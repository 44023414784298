import AxiosInterceptor from "../utils/AxiosInterceptor";

const CoursesManagementList = {
    getAll: (value) => AxiosInterceptor().post("/get-all-course", value),
    edit: (value) => AxiosInterceptor().post("/update-course", value),
    getById: (value) => AxiosInterceptor().post("/get-course", value),
    add: (value) => AxiosInterceptor().post("/add-course", value),
    delete: (value) => AxiosInterceptor().post("/delete-course", value),
    taskget: (value) => AxiosInterceptor().post("/get-tasks", value),
    getteachers: (value) => AxiosInterceptor().get("/get-teachers", value),
    getAllCourseEntityCreateCourseVal: (value) => AxiosInterceptor().post("/getAllCourseEntityCreateCourseVal", value),  
    getAllCourseEntityCreateCourse: (value) => AxiosInterceptor().post("/get-dependent-course-entity", value),
};

export default CoursesManagementList;