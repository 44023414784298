import * as Yup from "yup";
import { MESSAGE } from "./validationMessage";
import { EMAIL_REGEX, MOBILE_REGEX, NOSPACE_REGEX, PASSWORDS_REGEX} from "./constants";

let email = Yup.string()
  .max(60, "Email should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .email(MESSAGE.EMAIL)
  .matches(EMAIL_REGEX, MESSAGE.EMAIL);

let name = Yup.string()
  .max(60, "Name should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let title = Yup.string()
  .max(60, "This field should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let description = Yup.string()
  .max(250, "Description should not be longer than 250 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let question = Yup.string()
  .max(150, "This field should not be longer than 150 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let status = Yup.mixed().required(MESSAGE.REQUIRED);

let mobile = Yup.string().matches(MOBILE_REGEX, MESSAGE.PHONE);

export const CreatorAddSchema = Yup.object({
  name,
  email,
  mobile,
  password: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], MESSAGE.CPASSWORD)
    .required(MESSAGE.REQUIRED),
});

export const CreatorEditSchema = Yup.object({
  question: Yup.string().required(MESSAGE.REQUIRED),
  slug: Yup.string().required(MESSAGE.REQUIRED),
});

const questionSchema = Yup.object().shape({
  options: Yup.array()
    .of(Yup.string()
        .min(2, "Choices must be longer than 2 characters")
        .max(24, "Choices should not be longer than 24 characters")
        .required("Choices is required")
        .matches(/^\S/, "Choices cannot start with a blank space"),
      ) 
    .min(2, 'At least two options are required.'), 
  correctAnswer: Yup.number()
    .required('Correct answer is required.'), 
});

export const TaskSchema = Yup.object({
  title: Yup.string()
    .min(2, "Title must be longer than 2 characters")
    .max(100, "Title should not be longer than 100 characters")
    .required("Title is required")
    .matches(/^\S/, "Title cannot start with a blank space"),
  description: Yup.string()
    .required("Description is required")
    .min(10, "Description must be at least 10 characters")
    .max(500, "Description cannot exceed 500 characters")
    .matches(/^\S/, "Description cannot start with a blank space"),
  languageId: Yup.string().required("Language is required"),
  questions: Yup.array()
    .of(questionSchema) 
    .min(1, 'At least one question is required.'),
  status: Yup.string()
    .required("Status is required"),
});

export const LanguageSchema = Yup.object({
  // title,
  status,
  title: Yup.string()
    .min(2, "Language Name must be longer than 2 characters")
    .max(100, "Language Name should not be longer than 100 characters")
    .required(MESSAGE.REQUIRED)
    .matches(/^\S/, "Language Name cannot start with a blank space"),
});

export const EmailTemplateSchema = Yup.object({
  title: Yup.string()
    .min(2, "Title must be longer than 2 characters")
    .max(100, "Title should not be longer than 100 characters")
    .required("Title is required")
    .matches(/^\S/, "Title cannot start with a blank space"),
  subject: Yup.string()
    .min(2, "Subject must be longer than 2 characters")
    .max(100, "Subject should not be longer than 100 characters")
    .required("Subject is required")
    .matches(/^\S/, "Subject cannot start with a blank space"),
  slug: Yup.string()
    .min(2, "Slug must be longer than 2 characters")
    .max(100, "Slug should not be longer than 100 characters")
    .required("Slug is required")
    .matches(/^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/, "Slug can only contain letters, numbers, and hyphens, and cannot start or end with a hyphen"),
   content: Yup.string()
    .min(10, "Description must be longer than 10 characters")
    .max(500, "Description should not be longer than 500 characters")
    .required("Description is required")
    .matches(/^\S/, "Description cannot start with a blank space"),
  status: Yup.string()
    .required("Status is required"),
});


export const Irbc = Yup.object({
  // title,
  status,
  title: Yup.string()
    .min(2, "Title must be longer than 2 characters")
    .max(100, "Title should not be longer than 100 characters")
    .required(MESSAGE.REQUIRED)
    .matches(/^\S/, "Title cannot start with a blank space"),
});

export const InstitutionSchema = Yup.object({
  // title,
  status,
  title: Yup.string()
    .min(2, "Institution Name must be longer than 2 characters")
    .max(100, "Institution Name should not be longer than 100 characters")
    .required(MESSAGE.REQUIRED)
    .matches(/^\S/, "Institution Name cannot start with a blank space"),
});
export const CourseLevelSchema = Yup.object({
  // title,
  status,
  title: Yup.string()
    .min(2, "Course Level Tital must be longer than 2 characters")
    .max(100, "Course Level Tital should not be longer than 100 characters")
    .required(MESSAGE.REQUIRED)
    // .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE)
    .matches(/^\S/, "Course Level Tital cannot start with a blank space"),
  subSlug: Yup.string().required(MESSAGE.REQUIRED),
});

export const CmsSchema = Yup.object({
  // title,
  status,
  title: Yup.string()
    .min(2, "Tital must be longer than 2 characters")
    .max(100, "Tital should not be longer than 100 characters")
    .required(MESSAGE.REQUIRED)
    .matches(/^\S/, "Tital cannot start with a blank space"),
  slug: Yup.string()
    .max(30, "Slug should not be longer than 30 characters")
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    // .matches(/^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/, "Slug can only contain letters, numbers, and hyphens, and cannot start or end with a hyphen"),
  subSlug: Yup.string()
    .max(30, "SubSlug should not be longer than 30 characters")
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
    // .matches(/^[a-zA-Z0-9]+(?:[-_][a-zA-Z0-9]+)*$/, "Slug can only contain letters, numbers, and hyphens, and cannot start or end with a hyphen"),
});

export const QuestionanaireSchema = Yup.object({
  fieldType: Yup.string().required(MESSAGE.REQUIRED),
  question: Yup.string()
    .required("Question is required")
    .matches(/^[^\s]+(?:\s.*)?$/, 'Question should not start with a blank space')
    .min(2, 'Question should be more than 2 characters')
    .max(499, 'Question must be at most 500 characters'),

  options: Yup.array()
    .of(
      Yup.string().when("$fieldType", {
        is: "Dropdown",
        then: (schema) =>
          schema
            .required(MESSAGE.REQUIRED)
            .max(20, "Option should not be longer than 20 characters") 
            .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
        otherwise: (schema) => schema.notRequired(),
      })
    )
    .when('fieldType', {
      is: 'Dropdown', 
      then: (schema) => schema.min(1, 'At least one option is required'),
      otherwise: (schema) => schema.notRequired(), 
    }),
});

const isDateInPast = (date) => date && new Date(date) < new Date();

export const CoursesManagementSchema = Yup.object().shape({
  name: Yup.string()
    .required("Course name is required")
    .min(1, "Course name must be at least 1 characters")
    .max(100, "Course name should not be longer than 100 characters")
    .matches(/^\S/, "Course Name cannot start with a blank space"),
  
  description: Yup.string()
    .required("Description is required")
    .min(1, "Description cannot be empty")
    .max(500, "Description should not be longer than 500 characters")
    .matches(/^\S/, "Description cannot start with a blank space"),

  languageId: Yup.mixed()
    .required("Language is required"),

  institutionType: Yup.string()
    .required("Institution Type is required"),


  year: Yup.string()
    .required("Year is required"),
  
  courseLevelId: Yup.object({
    _id: Yup.string().required("Course level is required")
  }),

  tasks: Yup.array().of(
    Yup.object().shape({
      taskId: Yup.string().required("Task is required"),
      cutOff: Yup.string().required("Task cut-off is required"),
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date()
        .required("End date is required")
        .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
      dueDate: Yup.date()
        .required("Due date is required")
        .min(Yup.ref('endDate'), 'Due date cannot be before end dates'),
    })
  ).min(1, "At least one task is required"),
  
  teacherId: Yup.object({
    _id: Yup.string()
        .required("Teacher email is required")
        .matches(/^\S/, "Teacher email cannot start with a blank space"),
   }),
  
  status: Yup.boolean().required("Status is required"),

  semesterId: Yup.object()
  .test('semester-required', 'Semester is required', function(value) {
    const { institutionType } = this.parent;
    if (institutionType === 'postsecondary') {
      return value && value._id;
    }
    return true;
  }),

  institutionId: Yup.object()
    .test('institution-required', 'Institution is required', function(value) {
      const { institutionType , courseLevelId, institutionId} = this.parent;
      if (institutionType === 'postsecondary') {
        if (courseLevelId?.title === "Other") {return true}
        else {return value && value._id;}
      }
      return true;
    }),    

  otherCourseLevelDescription: Yup.string()
    .min(1, "Other course level description cannot be empty")
    .max(500, "Other course level description should not be longer than 500 characters")
    .test(
      'other-course-level-required',
      "Other course level description is required",
      function (value) {
        const { courseLevelId } = this.parent;
        if (courseLevelId?.title === "Other") {
          return value && value.length >= 1 && value.length <= 500 && /^\S/.test(value);
        }
        return true; 
      }
    ).nullable(),
    
  otherInstitutionDescription: Yup.string()
    .min(1,  "Other institution description cannot be empty")
    .max(500, "Other institution description should not be longer than 500 characters")
    .test(
      'other-institution-required',
      "Other institution description is required",
      function (value) {
        const { institutionId } = this.parent;
        if (institutionId?.title === "Other") {
          return value && value.length >= 1 && value.length <= 500 && /^\S/.test(value);
        }
        return true;
      }
    ).nullable(),   
});

export const EditAddSchima = Yup.object({
  name:  Yup.string()
    .required("Teacher Name is required")
    .min(3, "Teacher Name must be at least 3 characters")
    .max(50, "Teacher Name cannot exceed 50 characters")
    .matches(/^\S/, "Teacher Name cannot start with a blank space"),
  email: Yup.string()
    .max(60, "Email should not be longer than 60 characters")
    .required(MESSAGE.REQUIRED)
    .email(MESSAGE.EMAIL)
    .matches(EMAIL_REGEX, MESSAGE.EMAIL),
  password: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], MESSAGE.CPASSWORD)
    .required(MESSAGE.REQUIRED),
});

export const EditTeacherSchima = Yup.object({
  name:  Yup.string()
    .required("Teacher Name is required")
    .min(3, "Teacher Name must be at least 3 characters")
    .max(50, "Teacher Name cannot exceed 50 characters")
    .matches(/^\S/, "Teacher Name cannot start with a blank space"),
  email: Yup.string()
    .max(60, "Email should not be longer than 60 characters")
    .required(MESSAGE.REQUIRED)
    .email(MESSAGE.EMAIL)
    .matches(EMAIL_REGEX, MESSAGE.EMAIL),
  password: Yup.string()
    .matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  cPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], MESSAGE.CPASSWORD)
});