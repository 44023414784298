import { TextField } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const SearchInput = ({ data, setSearch, placeholder }) => {
  const [searchValue, setSearchValue] = useState(data?.search || '');

  const updateSearch = useCallback(() => {
    setSearch((prev) => {
      if (prev.search !== searchValue) {
        return { ...prev, search: searchValue, current_page: 1 };
      }
      return prev;
    });
  }, [searchValue, setSearch]);

  useEffect(() => {
    const handler = setTimeout(updateSearch, 500);
    return () => clearTimeout(handler);
  }, [searchValue, updateSearch]);

  return (
    <div className='search_con'>
      <TextField
        label={placeholder ? placeholder : "Search"}
        variant="standard"
        className='search_input'
        inputProps={{
          autoComplete: 'new-password',
          style: { paddingRight: "20px" },
          form: {
            autoComplete: 'off',
          },
        }}
        value={searchValue}
        onChange={(e) => setSearchValue(e?.target?.value)}
      />
      {searchValue?.length > 0 && (
        <CloseIcon onClick={() => setSearchValue('')} style={{ cursor: 'pointer' }} />
      )}
    </div>
  );
};

export default SearchInput;
