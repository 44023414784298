import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Icon } from "@iconify/react";
import CardLayout from "../../layouts/CardLayout";
import TeacherManagementService from "../../services/TeacherManagementService";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { EditTeacherSchima } from "../../utils/Schema"; // Schema should be updated for password validation
import { Box, Stack, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material";

function EditTeacherManagement() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, SetLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await TeacherManagementService.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    })();
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      name: data?.name ? data?.name : "",
      email: data?.email ? data?.email : "",
      password: "",
      cPassword: "",
      isBlocked: data?.isBlocked !== undefined ? data?.isBlocked : false,
    },
    validationSchema: EditTeacherSchima,
    onSubmit: async (values) => {
      SetLoading(true);
      try {
        // Create a copy of values and remove password/cPassword if not provided
        let obj = {
          id: values.id,
          name: values.name,
          email: values.email,
          isBlocked: values.isBlocked,
        };

        // Add password to the payload only if it is provided
        if (values.password) {
          obj.password = values.password;
        }

        const response = await TeacherManagementService.edit(obj);
        toast.success(response?.data?.message);
        navigate(-1);
        SetLoading(false);
      } catch (error) {
        errorHandler(error);
        SetLoading(false);
      }
    },
    validate: (values) => {
      const errors = {};

      // Check if password and confirm password match
      if (values.password && values.password !== values.cPassword) {
        errors.cPassword = "Passwords do not match!";
      }

      // Additional validations (optional)
      if (!values.name) {
        errors.name = "Name is required";
      }

      if (!values.email) {
        errors.email = "Email is required";
      }

      return errors;
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <Box>
      <Helmet>
        <title>Edit Creator</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Edit Teacher" />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    error={Boolean(touched?.name && errors?.name)}
                    {...getFieldProps("name")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.name && errors?.name)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.name && errors?.name}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    error={Boolean(touched?.email && errors?.email)}
                    {...getFieldProps("email")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.email && errors?.email)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.email && errors?.email}
                  </FormHelperText>
                </Grid>

                {/* Set New Password */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Set New Password"
                    type="password"
                    error={Boolean(touched?.password && errors?.password)}
                    {...getFieldProps("password")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.password && errors?.password)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.password && errors?.password}
                  </FormHelperText>
                </Grid>

                {/* Confirm Password */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    error={Boolean(touched?.cPassword && errors?.cPassword)}
                    {...getFieldProps("cPassword")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.cPassword && errors?.cPassword)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.cPassword && errors?.cPassword}
                  </FormHelperText>
                </Grid>

                {/* isBlocked Field */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth error={Boolean(touched?.isBlocked && errors?.isBlocked)}>
                    <InputLabel id="isBlocked-select-label">Blocked</InputLabel>
                    <Select
                      labelId="isBlocked-select-label"
                      id="isBlocked-select"
                      value={values.isBlocked}
                      label="Blocked"
                      onChange={(e) => setFieldValue("isBlocked", e.target.value)}
                    >
                      <MenuItem value={false}> Active</MenuItem>
                      <MenuItem value={true}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                  <FormHelperText error={Boolean(touched?.isBlocked && errors?.isBlocked)}>
                    {touched?.isBlocked && errors?.isBlocked}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Stack
                spacing={2}
                mt={3}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  startIcon={<Icon icon="fa-solid:save" />}
                >
                  Update
                </LoadingButton>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default EditTeacherManagement;