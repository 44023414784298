import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, CardHeader, CardContent, Stack, Box } from "@mui/material";
import { TextField, FormHelperText, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import CardLayout from "../../layouts/CardLayout";
import TeacherManagementService from "../../services/TeacherManagementService";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditAddSchima } from "../../utils/Schema";

function AddTeacherManagement() {
  const navigate = useNavigate();
  const [loading, SetLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      password: "",
      cPassword: "",
      type:"teacher",
    },

    validationSchema: EditAddSchima,
    onSubmit: async (values) => {
      SetLoading(true);
      try {
        let obj = { ...values };
        delete obj["cPassword"];
        const response = await TeacherManagementService.add(obj);
        toast.success(response?.data?.message, { toastId: "error" });
        navigate(-1);
        SetLoading(false);
      } catch (error) {
        errorHandler(error);
        SetLoading(false);
      }
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Box>
      <Helmet>
        <title>Add Creator</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Add Teacher" />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    error={Boolean(touched?.name && errors?.name)}
                    {...getFieldProps("name")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.name && errors?.name)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.name && errors?.name}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    type="email"
                    error={Boolean(touched?.email && errors?.email)}
                    {...getFieldProps("email")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.email && errors?.email)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.email && errors?.email}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Set Password"
                    type="password"
                    required
                    error={Boolean(touched?.password && errors?.password)}
                    {...getFieldProps("password")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.password && errors?.password)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.password && errors?.password}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    required
                    error={Boolean(touched?.cPassword && errors?.cPassword)}
                    {...getFieldProps("cPassword")}
                  />
                  <FormHelperText
                    error={Boolean(touched?.cPassword && errors?.cPassword)}
                    sx={{ marginLeft: "14px" }}
                  >
                    {touched?.cPassword && errors?.cPassword}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Stack
                spacing={2}
                mt={3}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  startIcon={<Icon icon="fa-solid:save" />}
                >
                  Save
                </LoadingButton>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}
export default AddTeacherManagement;
