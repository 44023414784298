import AxiosInterceptor from "../utils/AxiosInterceptor";

const EmailTemplateList = {
    getAll: (value) => AxiosInterceptor().post("/get-all-templates", value),
    edit: (value) => AxiosInterceptor().post("/edit-template", value),
    getById: (value) => AxiosInterceptor().post("/get-one-template", value),
    add: (value) => AxiosInterceptor().post("/add-template", value),
    delete: (value) => AxiosInterceptor().post("/delete-template", value),
    sendMail: (value) => AxiosInterceptor().post("/sendEmailVal", value),
    // getDropdownLanguages: (value) =>
    // AxiosInterceptor().post("/get-all-course-entity-without-pagination", value),
};

export default EmailTemplateList;