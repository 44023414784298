import { Box, CardContent, CardHeader, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import FieldErrorMessage from "../../components/FieldErrorMessage";
import SaveButton from "../../components/SaveButton";
import CardLayout from "../../layouts/CardLayout";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { Irbc } from "../../utils/Schema";
import EditorJodit from "../../components/editor/editor";
import apiCms from "../../services/cmsService";

function EditAddIrbc() {
    const navigate = useNavigate();
    const id = 'irb-consent-form'
  
    const formik = useFormik({
        initialValues: {
            title: "",
            slug: "",
            subSlug:"",
            description: "",
        },
        validationSchema: Irbc,
        onSubmit: async (values, { setSubmitting }) => {
        try {
            const res = id
            ? await apiCms.edit(values)
            : await apiCms.add(values);

            toast.success(res?.data?.message);
            navigate("/dashboard");
        } catch (error) {
            errorHandler(error);
        } finally {
            setSubmitting(false);
        }
        },
    });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (id) {
      const getInstitutionById = async () => {
        try {
            const payload = {
                slug: 'irb-consent-form'
            };
            const response = await apiCms.getById(payload);
            const resData = response?.data?.data;
            resetForm({
            values: {
                id: resData?._id,
                title: resData?.title || "",
                slug: resData?.slug || "",
                subSlug: resData?.subSlug || "",
                description: resData?.description || "",
                status: resData?.status || "false",
            },
            });
        } catch (error) {
          errorHandler(error);
        }
      };

      getInstitutionById();
    }
  }, [id, resetForm]);

  return (
    <Box>
      <Helmet>
        <title>{id ? "" : "Add"} IRB Consent Form</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "" : "Add"} IRB Consent Form`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    error={Boolean(touched?.title && errors?.title)}
                    {...getFieldProps("title")}
                  />
                  <FieldErrorMessage formik={formik} name={"title"} />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Slug"
                    required
                    disabled
                    error={Boolean(touched?.slug && errors?.slug)}
                    {...getFieldProps("slug")}
                  />
                  <FieldErrorMessage formik={formik} name="slug" />
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Sub Slug"
                    required
                    disabled
                    error={Boolean(touched?.subSlug && errors?.subSlug)}
                    {...getFieldProps("subSlug")}
                  />
                  <FieldErrorMessage formik={formik} name="subSlug" />
                </Grid>

                <Grid item xs={12}>
                  <label>Description</label>
                  <EditorJodit
                    formik={formik}
                    fieldName="description"
                    onChange={(val) => formik.setFieldValue("description", val)}
                    data={formik.values.description}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <FormHelperText error>
                      {formik.errors.condescriptiontent}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <SaveButton loading={formik.isSubmitting} id={id} />
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default EditAddIrbc;