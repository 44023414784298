import { useRoutes, Navigate } from "react-router-dom";
import React from "react";

// Layouts
import PublicLayout from "../layouts/public";
import Page404 from "../pages/error/Page404";

// Auth Pages
import LoginPage from "../pages/auth/LoginPage";
import ForgetForm from "../pages/auth/ForgetPage";
import ResetPassword from "../pages/auth/ResetPasswordPage";

const AuthRouting = () => {
    const routes = [
        {
            element: <PublicLayout />,
            children: [
                { path: "404", element: <Page404 /> },
                { path: "login", element: <LoginPage /> },
                { path: "forgot", element: <ForgetForm /> },
                { path: "resetpassword", element: <ResetPassword /> },
                { path: "*", element: <Navigate to="/login" replace /> },
            ],
        },
        { path: "*", element: <Navigate to="/login" replace /> },
    ];

    return useRoutes(routes);
};

export default AuthRouting;
