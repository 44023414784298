import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiUsers = {
  edit: (value) => AxiosInterceptor().post(`/update-creator`, value),
  getAll: (values) => AxiosInterceptor().post(`/get-all-user`, values),
  getById: (values) => AxiosInterceptor().post(`/get-user`, values),
  changeStatus: (values) =>
    AxiosInterceptor().post(`/block-unblock-user`, values),
  documentVerification: (values) =>
    AxiosInterceptor().post(`/creator-verify`, values),
};

export default apiUsers;


