import AxiosInterceptor from "../utils/AxiosInterceptor";

const apiCms = {
  add: (value) => AxiosInterceptor().post(`/add-cms`, value),
  edit: (value) => AxiosInterceptor().post(`/update-cms`, value),
  delete: (value) => AxiosInterceptor().post(`/delete-cms`, value),
  getAll: (values) => AxiosInterceptor().post(`/get-all-cms`, values),
  getById: (values) => AxiosInterceptor().post(`/get-cms`, values),
  // changeStatus: (values) =>
  //   AxiosInterceptor().post(`/admin/user/change-status`, values),
};

export default apiCms;
