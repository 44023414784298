import AxiosInterceptor from "../utils/AxiosInterceptor";

const TeacherManagementService = {
    getAll: (value) => AxiosInterceptor().post("/get-all-user", value),
    edit: (value) => AxiosInterceptor().post("/update-user", value),
    getById: (value) => AxiosInterceptor().post("/get-user", value),
    add: (value) => AxiosInterceptor().post("/add-user", value),
    delete: (value) => AxiosInterceptor().post("/delete-user", value),
    taskget: (value) => AxiosInterceptor().post("/get-all-questionnaire-tasks", value),
    updateStatus: (value) => AxiosInterceptor().post("/block-unblock-user", value),
};

export default TeacherManagementService;