import { toast } from "react-toastify";

const errorHandler = (err) => {
  if (err.response?.data) {
    const { message, status, error } = err.response?.data;
    if (status === 0 && Array.isArray(error) && error.length > 0) {
      error.map((err) => toast.error(err.message));
      return;
    }
    if (message) {
      toast.error(message, { toastId: "error" });
      return;
    }

    toast.error("Something went wrong!", { toastId: "error1" });
  } else {
    toast.error("An error occurred", { toastId: "error2" })
  }
};

export default errorHandler;
