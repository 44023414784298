// export const BASE = "http://172.16.2.149:3000/";
// export const BASE = "http://172.16.1.250:3000/";
export const BASE = "https://towson-api.devtechnosys.tech/";

export const API_BASE = BASE + "admin";

export const AUDIO_BASE = "https://dev-test-towson.s3.us-east-2.amazonaws.com/";


export const MOBILE_REGEX = /^[0-9]{10}$/;

export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&~"^+-/=,.?(){}[|\]<>:'_;*?])[A-Za-z\d#$@!%^&~"^+-/=,.?(){}[|\]<>:'_;*?]{8,}$/g;

//export const EMAIL_REGEX = /^(?!\.)[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,3}){1,2}$/;
// export const EMAIL_REGEX = /^(?!\.)([a-zA-Z0-9._]+)(?!-)[a-zA-Z0-9]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_REGEX = /^(?![.+-])([a-zA-Z0-9._+-]+)(?!\.)[a-zA-Z0-9]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;

// export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/webm",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/;

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const REMOVEHTML = /(<([^>]+)>)/gi;

export const REMOVENBSP = /&nbsp;/g

export const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Prefer not to say", label: "Prefer not to say" },
];

export const STATUS_OPTIONS = [
  { value: false, label: "Active" },
  { value: true, label: "Inactive" },
];
export const Verification_OPTIONS = [
  { value: "pending", label: "Pending" },
  { value: "cancelled", label: "Cancelled" },
];
export const PLAN_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

export const AUDIO_FORMATS = ["audio/mpeg", "audio/wav", "audio/ogg"];