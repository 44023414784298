import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent, Stack, Button, Box} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import EmailTemplateList from "../../services/emailTemplateLits"
import { Icon } from "@iconify/react";

const ViewEmailTemplate = () => {
  const { id } = useParams();
  const [EmailData, setEmailData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await EmailTemplateList.getById({ id: id });
        setEmailData(response.data.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/email-template/edit/${id}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Helmet>
        <title>Email Template Management</title>
      </Helmet>
      <CardLayout>
        <Card>
          <CardHeader title="Email Template Management" />
          <CardContent>
            <Grid container spacing={3}>
              {[
                { label: "Title", value: EmailData?.title },
                { label: "Subject", value: EmailData?.subject },
                { label: "Slug", value: EmailData?.slug },
                { label: "Created date", value: new Date(EmailData?.createdAt).toLocaleDateString("en-US") },
                {
                  label: "Description",
                  value: <div dangerouslySetInnerHTML={{ __html: EmailData?.content }} className="html-template linkdisable" />
                },
                { label: "Status", value: EmailData?.status ? "Active" : "Deactivate" },
              ].map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Stack spacing={1}>
                    <Typography variant="subtitle1">{item.label}</Typography>
                    <Typography variant="body1">{item.value || ""}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Stack
              spacing={2}
              mt={3}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                startIcon={<Icon icon="fa-solid:edit" />}
                onClick={handleEdit}
                sx={{ textTransform: 'none' }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ textTransform: 'none' }}
              >
                Cancel
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </CardLayout>
    </Box>
  );
};

export default ViewEmailTemplate;
